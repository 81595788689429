import {graphql} from 'gatsby';
import List from './List';

export const pageQuery = graphql`
query categoryQuery($skip: Int!, $limit: Int!, $category: String!) {
  allMarkdownRemark(
    filter: {frontmatter: {category: {eq: $category}}},
    sort: {order: DESC, fields: [frontmatter___date]},
    skip: $skip,
    limit: $limit
  ) {
    edges {
      node {
        id
        excerpt
        htmlAst
        frontmatter {
          title
          path
          date(formatString: "Do MMMM YYYY")
          category
        }
      }
    }
  }
}
`;

export default List;
